import { navigate } from 'gatsby';
import { urlBasedPageData } from './Data';

const formValidation = (...fields) => {
  const formFields = [...fields];
  let lenCheck = formFields.filter((field) => field.length > 2);
  if (lenCheck.length >= 2) {
    return true;
  } else return false;
};

const checkoutFormValidation = (
  firstName,
  lastName,
  zipCode,
  city,
  phone,
  address1,
  selectedState
) => {
  const firstCheck = firstName.length >= 2 ? true : false;
  const lastCheck = lastName.length >= 2 ? true : false;
  const zipCheck = zipCode.length >= 5 ? true : false;
  const cityCheck = city.length > 3 ? true : false;
  const phoneCheck = phone.length > 7 ? true : false;
  const address1Check = address1.length ? true : false;
  const stateCheck = selectedState.length >= 2 ? true : false;

  if (
    firstCheck &&
    lastCheck &&
    zipCheck &&
    cityCheck &&
    phoneCheck &&
    address1Check &&
    stateCheck
  ) {
    return true;
  }
  return false;
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const privateCheckoutRoute = (item, location) => {
  if (localStorage.getItem(item)) {
    return;
  } else {
    navigate(location);
  }
};

const randomNumberGenerator = (max) => Math.floor(Math.random() * max);

const storeUTMParams = (queryString) => {
  localStorage.setItem('source', getUTMParam(queryString, 'source'));
  localStorage.setItem('medium', getUTMParam(queryString, 'medium'));
  localStorage.setItem('campaign', getUTMParam(queryString, 'campaign'));
};

const getUTMParam = (queryString, paramStr) => {
  //paramStr should be source, medium, campaign, etc
  const paramsArr = queryString.split(/[?&]+/);
  let parameter = paramsArr
    .filter((param) => {
      if (
        param.substring(param.indexOf('_') + 1, param.indexOf('=')) === paramStr
      ) {
        return param.substring(param.indexOf('=') + 1, param.length - 1);
      } else return '';
    })
    .toString();

  return parameter.substring(parameter.indexOf('=') + 1, parameter.length);
};

const correctUrl = (desiredUrl, url, seperator = '/') => {
  return url.split(seperator).includes(desiredUrl);
};

//distribute data depending on the url
const urlDataDistributor = (url, page) => {
  const data = urlBasedPageData;
  //check url prefix. if on store, return store data, etc
  if (correctUrl('https://offer', url, '.')) {
    return page === 'checkout' ? data.offer.checkout : data.offer.index;
  } else if (correctUrl('https://assessment', url, '.')) {
    return page === 'checkout' ? data.labcorp.checkout : data.labcorp.index;
  } else return page === 'checkout' ? data.store.checkout : data.store.index;
};

//push data to google tag manager data layer
const pushDataToGTM = (data) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    window.dataLayer.push(data);
  } else {
    console.log('window is undefined');
  }
};

//validate postal address for postgrid
const validatePostalAddress = (address) => {};

export {
  formValidation,
  privateCheckoutRoute,
  validateEmail,
  checkoutFormValidation,
  randomNumberGenerator,
  storeUTMParams,
  correctUrl,
  pushDataToGTM,
  validatePostalAddress,
  urlDataDistributor,
};
