import React, { useContext, useEffect } from 'react';
import { Button } from '@honehealth/components';
import { myContext } from '../components/utils/ContextProvider';
import { privateCheckoutRoute } from '../components/utils/Utils';
import { correctUrl } from '../components/utils/Utils';
import Modal from '../components/Modal';
import NavBar from '../components/Navbar';

export default function Confirmation() {
  const globalContext = useContext(myContext);
  let location = typeof window !== 'undefined' ? window.location.href : '';
  let phoneNum =
    typeof window !== 'undefined'
      ? localStorage.getItem('phoneNumber')
      : '111-111-1111';
  let firstName =
    typeof window !== 'undefined'
      ? localStorage.getItem('firstName')
      : 'First ';
  let lastName =
    typeof window !== 'undefined' ? localStorage.getItem('lastName') : 'Last';
  let emailAddress =
    typeof window !== 'undefined' ? localStorage.getItem('emailAddress') : '';

  useEffect(() => {
    privateCheckoutRoute('num', '/onepagecheckout');

    if (typeof window !== 'undefined') {
      if (window.gtag != null) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-671654442/DDr2CLjLkr8CEKrEosAC',
          value: 45.0,
          currency: 'USD',
          transaction_id: '',
        });
      }
    }
  }, []);

  return (
    <>
      <NavBar />
      <div className="min-h-screen flex items-center justify-center">
        <title>Confirmation</title>
        <div className="bg-softWhite w-full p-4 lg:p-8 rounded shadow-2xl lg:w-2/3">
          <div className="space-y-3 p-2 pt-4">
            <h1 className="text-3xl font-bold mb-4 text-black text-center">
              You did it!
            </h1>
            <p className="font-bold mb-4 text-black text-center">
              Your order has been successfully processed!
            </p>
            <p className="font-bold mb-4 text-black text-center">
              Your Order Number is: {String(globalContext.orderNumber)}
            </p>
            <h4 className="text-center text-black text-lg">
              Want to more easily track the status of an order, register your
              at-home lab testing kit, and manage your account? Create your My
              Hone account to do this and more!
            </h4>
            <p className="mb-4 text-black text-center">
              Join our community to get honed in while you wait!
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 pt-3">
            <Button className=" w-full transition duration-300 block h-16">
              <a href="https://app.honehealth.com/register">
                CREATE MY HONE ACCOUNT
              </a>
            </Button>
            <Button className="w-full transition duration-300 block h-16">
              <a href="https://app.honehealth.com/login">
                CONTINUE W/O ACCOUNT
              </a>
            </Button>
            <Button className="w-full transition duration-300 block h-16">
              <a href="https://honehealth.circle.so/home">JOIN OUR COMMUNITY</a>
            </Button>
            <div className="w-full">
              <p>
                Already a member?{' '}
                <a
                  className="text-red-500"
                  href="https://app.honehealth.com/login"
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
        {correctUrl('https://assessment', location, '.') ? (
          <Modal
            header="We've got your back!"
            first={String(firstName)}
            last={String(lastName)}
            email={String(emailAddress)}
            phone={String(phoneNum)}
          />
        ) : (
          <div />
        )}
      </div>
    </>
  );
}
