import { Button } from '@honehealth/components';
import React from 'react';
import { useState } from 'react';
import { openPopupWidget } from 'react-calendly';

export default function Modal(props) {
  const [showModal, setShowModal] = useState(true);
  let {
    header,
    body,
    buttonText,
    buttonClass,
    buttonAction,
    first,
    last,
    email,
    phone,
  } = props;

  const url =
    'https://calendly.com/kalam-honehealth/hone-coach-introduction-kash?month=2022-01';
  const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '00a2ff',
    textColor: '4d5055',
  };

  const utm = {
    utmCampaign: 'Labcorp Trial',
    utmContent: 'Labcorp test and doctor consultation',
    utmMedium: 'Ad',
    utmSource: 'Facebook',
    utmTerm: 'labcorp',
  };

  const prefill = {
    email: email,
    firstName: first,
    lastName: last,
    name: `${first} ${last}`,
    guests: [],
    customAnswers: {},
    date: new Date(Date.now() + 86400000),
  };

  const calendarOnCLick = () => {
    openPopupWidget({ url, prefill, pageSettings, utm });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-4 pb-2">
                  <h3 className="text-2xl font-bold items-center">{header}</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-2xl leading-none  outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black">×</span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-2 text-blueGray-500 text-lg leading-relaxed">
                    Schedule a session with your Hone coach below to get
                    started! Please have your insurance details ready.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <Button
                    className="w-full transition duration-300 h-16 block"
                    onClick={calendarOnCLick}
                  >
                    Schedule Here
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
