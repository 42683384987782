import React from 'react';
import boxgif from '../../images/static-feature-image1.png';
import dnagif from '../../images/homepage-mission-icon-2.gif';

const usaStates = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

const servedStates = [
  'AZ',
  'CA',
  'CO',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KY',
  'ME',
  'MD',
  'MA',
  'MI',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NM',
  'NC',
  'OH',
  'OK',
  'OR',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'FL',
];

const testimonials = [
  {
    id: 0,
    title: 'Great product',
    data: '"The doctors give you careful attention and guidance toward your goals, while the rest of their crew makes their behind-the-scenes magic happen."',
    name: 'Salvatore',
    link: 'https://www.trustpilot.com/reviews/61145fc3f9f4870a2806bfe3',
  },
  {
    id: 1,
    title: 'Super easy and convenient',
    data: '"Super easy and convenient. Simple process to get started, did most of it from my desk."',
    name: 'David',
    link: 'https://www.trustpilot.com/reviews/611524439a633a1e92161a5e',
  },
  {
    id: 2,
    title: 'Thank you Hone',
    data: '"It\'s not just about hitting specific numbers on my lab work. It\'s about making me feel and perform the best I can."',
    name: 'Stephen',
    link: 'https://www.trustpilot.com/reviews/6127b0e49c391649d84a3762',
  },
];

const howDidYouHearOptions = [
  {
    key: 0,
    data: 'Youtube',
  },
  {
    key: 1,
    data: 'Tiktok',
  },
  {
    key: 2,
    data: 'Friend',
  },
  {
    key: 3,
    data: 'Facebook',
  },
  {
    key: 4,
    data: 'Podcast',
  },
  {
    key: 5,
    data: 'Google Search',
  },
  {
    key: 6,
    data: 'Newspaper / magazine ad',
  },
  {
    key: 7,
    data: 'Other Website',
  },
];

const urlBasedPageData = {
  labcorp: {
    checkout: {
      origPrice: '60',
      price: '5',
      stripePrice: 500,
      discount: '55',
      tracker: 'Labcorp Checkout',
      honePrice: 5.0,
      productId: 46,
      checkoutFeature: (
        <div className="grid grid-cols-2 w-full font-montserrat font-bold">
          <h1 className="text-md col-span-2 text-black text-center w-full">
            Hormone assessment
          </h1>
          <h1 className="text-md col-span-2 text-black text-center w-full">
            &
          </h1>
          <h1 className="text-md col-span-2 text-black text-center w-full">
            doctor consultation
          </h1>
        </div>
      ),
      image: dnagif,
      shipping: 'N/A',
      disclaimer: 'you must use your own insurance to complete the lab work',
    },
    index: {
      origPrice: '60',
      price: '5',
      tracker: 'LabCorp CTA',
      discount: '55',
      title: 'Hone Hormone Assessment',
      subTitle: {
        text: 'Order your lab and video to chat with a physician to qualify for treatment.',
        style:
          'xl:text-xl col-span-3 text-black text-center pb-1 text-lg',
      },
      image: dnagif,
    },
  },
  store: {
    checkout: {
      origPrice: '60',
      price: '45',
      stripePrice: 4500,
      discount: '15',
      tracker: '45 Dollar Checkout',
      honePrice: 45.0,
      productId: 46,
      checkoutFeature: (
        <div className="grid grid-cols-2 w-full font-montserrat font-bold">
          <h1 className="text-lg col-span-2 text-black text-center w-full pt-2">
            At home test
          </h1>
          <h1 className="text-lg col-span-2 text-black text-center w-full">
            &
          </h1>
          <h1 className="text-lg col-span-2 text-black text-center w-full pb-2">
            consultation
          </h1>
        </div>
      ),
      image: boxgif,
      shipping: 'FREE',
      disclaimer: 'you must use your own insurance to complete the lab work',
    },
    index: {
      origPrice: '60',
      price: '45',
      tracker: '45 Dollar CTA',
      discount: '15',
      title: 'At Home Hormone Assessment',
      subTitle: {
        text: 'Order your at-home test and consultation.',
        style:
          'xl:text-2xl col-span-3 text-black text-center pb-1 text-lg',
      },
      image: boxgif,
    },
  },
  offer: {
    checkout: {
      origPrice: '90',
      price: '60',
      stripePrice: 6000,
      discount: '30',
      tracker: '60 Dollar Checkout',
      honePrice: 60.0,
      productId: 46,
      checkoutFeature: (
        <div className="grid grid-cols-2 w-full space-y-[-2px] font-montserrat font-bold">
          <h1 className="text-lg col-span-2 text-black text-center w-full pt-2">
            At home test
          </h1>
          <h1 className="text-lg col-span-2 text-black text-center w-full">
            &
          </h1>
          <h1 className="text-lg col-span-2 text-black text-center w-full pb-2">
            consultation
          </h1>
        </div>
      ),

      image: boxgif,
      shipping: 'FREE',
      disclaimer: 'you must use your own insurance to complete the lab work',
    },
    index: {
      origPrice: '90',
      price: '60',
      tracker: '60 Dollar CTA',
      discount: '30',
      title: 'At Home Hormone Assessment',
      subTitle: {
        text: 'Order your at-home test and consultation.',
        style:
          'xl:text-2xl col-span-3 text-black text-center pb-1 text-lg',
      },
      image: boxgif,
    },
  },
};

export {
  usaStates,
  servedStates,
  testimonials,
  howDidYouHearOptions,
  urlBasedPageData,
};
